<template lang="pug">
  tr
    td.table-item.insurance-name {{ insurance.sources_insurance.name.$model }}
    td.table-item.fee-settings
      .d-flex
        AppDropdown.cy-insurance-fee-type(
          close-on-select
          order-direction="keep"
          :items="feeTypes"
          :value="currentFeeType"
          :class="{ invalid: insurance.fee_type.$error }"
          @select="selectCurrentFeeType"
        )
        .price-input-container
          .fee
            span.title {{ $t('plan_settings.basic_settings.fee') }}
            AppInput(
              type="number"
              :value="insurance.fee.$model"
              :class="{ 'is-invalid': insurance.fee.$error }"
              @input="updateField('fee', $event)"
            )
            span.yen {{ $t("plan_settings.basic_settings.currency") }}
          .max-fee
            span.title {{ $t('plan_settings.basic_settings.max') }}
            AppInput(
              type="number"
              :value="insurance.max_fee.$model"
              :class="{ 'is-invalid': insurance.max_fee.$error }"
              @input="updateField('max_fee', $event)"
            )
            span.yen {{ $t("plan_settings.basic_settings.currency") }}
</template>

<script>
  import { find } from "lodash-es"
  import { getInsuranceFeeTypes } from "@/config/translations_helper"

  export default {
    props: {
      insurance: Object,
      pairedInsurance: Object
    },

    components: {
      AppInput: () => import("@/components/elements/AppInput"),
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    computed: {
      feeTypes() {
        return getInsuranceFeeTypes(this.$i18n)
      },

      currentFeeType() {
        return find(this.feeTypes, ({ id }) => id === this.insurance.fee_type.$model)
      }
    },

    methods: {
      selectCurrentFeeType({ id }) {
        this.updateField("fee_type", id)
      },

      updateField(fieldName, value) {
        this.insurance[fieldName].$model = value
        if (this.pairedInsurance) this.pairedInsurance[fieldName].$model = value
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .table-item
    &.insurance-name
      width: 35%

    &.fee-settings
      width: 65%

      .price-input-container
        padding-left: 10px

        .fee,
        .max-fee
          +fees-container
</style>
